import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';


const cmsPageRepository = {
//   getCmsResult: async (url) => {
//     return await syncUpPlatformRequest({
//      url:url
//     });
//   }

  // getCmsResult: async ({url}) => {
  //   console.log(url,"repo url")

  //   const response = await fetch(url);

  //   console.log(response,"response")
  //   if (response.ok) {
  //     const responseText = await response.text();
  //     if (responseText) {
  //       return JSON.parse(responseText);
  //     }
  //     return;
  //   }
  //   throw Error(response.status);
  // }

  getCmsResult: async ({
    syncUpAppName,
    responseStatus = false,
    body
  }) => {
    const { resources, headers, basePath, httpMethods } = config.apiSettings.syncUpPlatform;
    const base = basePath.adminTlifeSearch;
    /* for tlife search */
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.cmsCdnRes}`,
      basePath: base,
      body: body,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

};

export default cmsPageRepository;
